<template>
  <div class="card-slide" @click="clickButton(button?.url)">
    <picture v-not-lazy :key="backgroundSourceKey" class="card-slide__picture card-slide__picture--back" data-not-lazy>
      <source :media="'(max-width: 1279px)'" :data-srcset="createSrcSet(props.images?.mobile?.backgroundImage)" />
      <source :media="'(min-width: 1280px)'" :data-srcset="createSrcSet(props.images?.desktop?.backgroundImage)" />
      <atomic-image
        class="card-slide__img card-slide__img--back"
        :src="backgroundImage"
        not-lazy
        @loadingError="errorLoadBackground"
      />
    </picture>

    <picture v-not-lazy :key="faceSourceKey" class="card-slide__picture card-slide__picture--front" data-not-lazy>
      <source :media="'(max-width: 1279px)'" :data-srcset="faceSource.pictureMobile" />
      <source :media="'(min-width: 1280px)'" :data-srcset="faceSource.pictureDesktop" />
      <atomic-image
        class="card-slide__img card-slide__img--front"
        :src="faceImage"
        :default-image="faceSource.defaultImg"
        not-lazy
        @loading-error="errorLoadFace"
      />
    </picture>

    <div class="card-slide__info">
      <div v-if="title" class="card-slide__title" v-html="marked.parse(title)" />
      <div v-if="content" class="card-slide__content">
        <span v-html="marked.parse(content.replace(/\*/g, '').replace(/;/g, ','))" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { marked } from 'marked';

  import type { ISliderItem } from '~/types';

  import { ModalName } from '@skeleton/consts/modals';

  const props = defineProps<ISliderItem>();

  const faceImage = ref(props.images?.mobile?.faceImage);
  const backgroundImage = ref(props.images?.mobile?.backgroundImage);

  const { isLoggedIn } = storeToRefs(useProfileStore());
  const { openModalSync } = useModalStore();
  const { openWalletModal } = useTransactionStore();
  const globalStore = useGlobalStore();
  const { defaultLocale } = storeToRefs(globalStore);
  const { createSrcSet, localizePath } = useProjectMethods();

  const changeImgUrlToDefaultLocale = (imageUrl: string = '') => {
    const regex = /\/img\/uploads\/[^/]+\/([^/]+)$/;
    const replacement = `/img/uploads/${defaultLocale.value?.code}/$1`;
    return imageUrl.replace(regex, replacement);
  };

  const faceSourceKey = ref(0);
  const backgroundSourceKey = ref(0);

  const faceSource = ref({
    pictureMobile: createSrcSet(props.images?.mobile?.faceImage),
    pictureDesktop: createSrcSet(props.images?.desktop?.faceImage),
    defaultImg: changeImgUrlToDefaultLocale(props.images?.mobile?.faceImage),
  });

  const backGroundSource = ref({
    pictureMobile: createSrcSet(props.images?.mobile?.backgroundImage),
    pictureDesktop: createSrcSet(props.images?.desktop?.backgroundImage),
    defaultImg: changeImgUrlToDefaultLocale(props.images?.mobile?.backgroundImage),
  });

  const errorLoadBackground = () => {
    backGroundSource.value.pictureMobile = createSrcSet(props.images?.mobile?.backgroundImage)
      .split(',')
      .map(item => changeImgUrlToDefaultLocale(item))
      .join(',');

    backGroundSource.value.pictureDesktop = createSrcSet(props.images?.desktop?.backgroundImage)
      .split(',')
      .map(item => changeImgUrlToDefaultLocale(item))
      .join(',');

    if (backgroundSourceKey.value) {
      backgroundSourceKey.value++;
    }
  };

  const errorLoadFace = () => {
    faceSource.value.pictureMobile = createSrcSet(props.images?.mobile?.faceImage)
      .split(',')
      .map(item => changeImgUrlToDefaultLocale(item))
      .join(',');

    faceSource.value.pictureDesktop = createSrcSet(props.images?.desktop?.faceImage)
      .split(',')
      .map(item => changeImgUrlToDefaultLocale(item))
      .join(',');

    if (!faceSourceKey.value) {
      faceSourceKey.value++;
    }
  };

  const clickButton = (url?: string) => {
    if (url) {
      if (props.openInNewTab) return window.open(url, '_blank');

      const router = useRouter();
      router.push(localizePath(url));
    } else isLoggedIn.value ? openWalletModal('deposit') : openModalSync(ModalName.SIGN_UP);
  };
</script>

<style src="~/assets/styles/components/card/slide.scss" lang="scss" />
