<template>
  <div class="main-slider">
    <div class="left-icon" @click="scrollTo(selectedIndex - 1)">
      <atomic-icon id="back" />
    </div>
    <div class="right-icon" @click="scrollTo(selectedIndex + 1)">
      <atomic-icon id="back" />
    </div>
    <div ref="sliderNode" class="main-slider__viewport">
      <div class="main-slider__container">
        <div v-for="(slide, index) in slides" :key="slide.title + '-' + index" class="main-slider__slide">
          <card-slide v-bind="slide" />
        </div>
      </div>
      <div class="main-slider__bullets">
        <div
          v-for="index in slides.length"
          :key="'bullet-' + index"
          class="main-slider__bullet"
          :class="{'is-selected': index === selectedIndex+1}"
          @click="scrollTo(index-1)"
        />
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">
  import emblaCarouselVue from 'embla-carousel-vue';
  import Autoplay from 'embla-carousel-autoplay';
  import type { ISliderItem } from '~/types';

  defineProps<{
    slides: ISliderItem[];
  }>();

  const selectedIndex = ref(0);

  const autoplayOptions = {
    delay: 4000,
    stopOnInteraction: true,
  };

  const [sliderNode, emblaApi] = emblaCarouselVue(
    {
      loop: true,
      align: 'start',
    },
    [Autoplay(autoplayOptions)]
  );

  const scrollTo = (index: number) => {
    if(!emblaApi.value) return;
    emblaApi.value?.scrollTo(index);
  };

  watchEffect(() => {
    if (emblaApi.value) {
      emblaApi.value.on('select', (sliderApi) => {
        selectedIndex.value = sliderApi.selectedScrollSnap();
      });
    }
  });
</script>

<style src="~/assets/styles/components/main-slider.scss" lang="scss" />
